// eslint-disable-next-line no-unused-vars
import React from 'react'
import { base, system } from '@theme-ui/presets'

const fontSizes = {
  xs: 14,
  sm: 16,
  md: 18,
  lg: 24,
  xl: 28,
  '2xl': 32,
  '3xl': 48,
  '4xl': 50,
  '5xl': 64,
}
const normalizedFontSizes = {
  ...fontSizes,
  ...Object.fromEntries(Object.entries(fontSizes).map((entry, i) => [i, entry[1]])),
}

const theme = {
  ...base,
  ...system,
  colors: {
    text: '6e6e6e',
    heading: '#474747',
    background: '#fefefe',
    bg: '#fefefe',
    primary: '#d52925',
    secondary: '#c0392b',
    tertiary: '#be2f2b',
    quarternary: '#bb2420',
    muted: '#f6f6f6',
    highlight: '#efeffe',
    gray: '#979797',
    accent: '#609',
    dark: '#363636',
  },
  fontSizes: normalizedFontSizes,
  fonts: {
    body: 'Calibre,system-ui, sans-serif',
    heading: 'Barlow Condensed, Barlow, sans-serif',
    monospace: 'Menlo, monospace',
  },
  lineHeights: {
    body: 1.6,
    heading: 1.125,
  },
  sizes: [0, 400, 500, 1600],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  styles: {
    ...system.styles,
    p: {
      fontFamily: 'body',
      fontWeight: '400',
      lineHeight: 'body',
      fontSize: [2, 2, 3],
    },
    h1: {
      color: 'primary',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [7],
      mt: 3,
      mb: 3,
    },
    h2: {
      mt: 4,
      mb: 2,
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [6],
    },
    h3: {
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [5],
      mt: 4,
      mb: 3,
    },
    h4: {
      my: 2,
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
    },
    h5: {
      my: 2,
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h6: {
      color: 'heading',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    li: { fontSize: [2, 3] },
  },
}
export default theme
